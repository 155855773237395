import { ChangeEvent, MouseEvent, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { getContentUrl } from '@leagueplatform/league-content-api';
import { StackLayout, Box, Button, styled } from '@leagueplatform/genesis-core';
import { useUserProfilePhoto } from 'hooks/use-user-profile-photo/use-user-profile-photo.hook';
import { useUserName } from 'hooks/user-user-name/use-user-name.hook';

const InvisibleInput = styled('input', {
  display: 'none',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

type ProfilePictureFormProps = {
  fileInputOnClickCallback?: (hasProfilePhoto?: boolean) => void;
};

export const ProfilePictureForm = ({
  fileInputOnClickCallback = () => {},
}: ProfilePictureFormProps) => {
  const { formatMessage } = useIntl();

  const { profilePhotoId, uploadProfilePhoto } = useUserProfilePhoto();
  const { userInitials } = useUserName();

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <StackLayout horizontalAlignment="center">
      <Box css={{ position: 'relative' }}>
        <Box
          data-testid="profile-image"
          className={profilePhotoId ? 'background-image' : ''}
          css={{
            width: 128,
            height: 128,
            borderRadius: '$circle',
            backgroundColor: '$surfaceCardPrimary',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            typography: '$headingOne',
            ...(profilePhotoId && {
              backgroundImage: `url(${getContentUrl(profilePhotoId)})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '50%',
            }),
          }}
        >
          {!profilePhotoId && userInitials}
        </Box>
        <InvisibleInput
          id="photo-file-input"
          ref={inputRef}
          type="file"
          accept="image/*"
          data-testid="file-input"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            if (!e.currentTarget.files) return;
            const file = e?.currentTarget?.files[0];
            if (file) {
              uploadProfilePhoto(file);
            }
          }}
          onClick={(e: MouseEvent<HTMLInputElement>) => {
            (e.target as HTMLInputElement).value = '';
            fileInputOnClickCallback(!!profilePhotoId);
          }}
        />
        <Button
          hideLabel
          icon="interfaceEdit"
          size="small"
          onClick={() => inputRef?.current?.click()}
          css={{ position: 'absolute', bottom: 0, right: '$half' }}
        >
          {formatMessage({
            id: profilePhotoId
              ? 'STR_UPDATE_PROFILE_PHOTO'
              : 'STR_ADD_PROFILE_PHOTO',
          })}
        </Button>
      </Box>
    </StackLayout>
  );
};
