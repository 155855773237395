import { Box, Button } from '@leagueplatform/genesis-core';
import { FormProvider } from '@leagueplatform/web-common';
import { RHFTextInput } from 'components/form-elements/rhf-text-input.component';
import { useCallback } from 'react';
import { useSelector } from '../hooks/useSelector';
import { useSend } from '../hooks/useSend';
import { assertDefined } from '../utils';

type FormFieldValues = {
  dentalProviderNumber?: string;
  dentalOfficeName?: string;
};

/**
 * Shown when the user is ready to make selections pertaining to their care team.
 */
export const YourCareTeamView = () => {
  const selectedPCP = useSelector((snapshot) => snapshot.context.selectedPCP);

  const hasDentalCoverage = useSelector(
    (snapshot) => snapshot.context.selectedPlan!.dentalCoverage,
  );

  const send = useSend();

  const submitHandler = useCallback(
    ({ dentalProviderNumber, dentalOfficeName }: FormFieldValues) => {
      if (dentalProviderNumber || dentalOfficeName) {
        send({
          type: 'submitDentalInformation',
          dentalInformation: {
            dentalProviderNumber,
            dentalOfficeName,
          },
        });
      } else {
        send({ type: 'continue' });
      }
    },
    [send],
  );

  const continueHandler = useCallback(() => {
    send({ type: 'continue' });
  }, [send]);

  let currentPCP = "you don't have one selected yet";

  if (selectedPCP) {
    assertDefined(selectedPCP.providerName);

    currentPCP = `current PCP: ${selectedPCP.providerName?.fullName}`;
  }

  return (
    <>
      <Box>
        {currentPCP}
        <a href="https://www.example.com">Select a new PCP</a>
      </Box>
      {hasDentalCoverage ? (
        <FormProvider<FormFieldValues>
          formOptions={{ mode: 'onSubmit' }}
          onSubmit={submitHandler}
        >
          <RHFTextInput
            id="dentalProviderNumber"
            label="Dental provider number"
          />
          <RHFTextInput id="dentalOfficeName" label="Dental office name" />

          <Button type="submit">Submit</Button>
        </FormProvider>
      ) : (
        <Button type="button" onClick={continueHandler}>
          continue
        </Button>
      )}
    </>
  );
};
