import { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Button,
  ImageMessage,
  StackLayout,
  HeadingText,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { Prompt, useHistory, type Location } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { ResponsiveButtonRow } from 'components/responsive-button-row/responsive-button-row.component';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import EXIT from 'assets/exit-warning-illustration.png';
import {
  useExitWarningContext,
  useExitWarningDispatchContext,
  ReducerActionKind,
} from './exit-warning.context';

type ExitWarningProps = {
  headingText?: string;
  bodyText?: string;
  stayButtonText?: string;
  leaveButtonText?: string;
};

export const ExitWarning = ({
  headingText,
  bodyText,
  stayButtonText,
  leaveButtonText,
}: ExitWarningProps) => {
  const { formatMessage } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isBelowTablet = useIsBelowTablet();
  const dirtyForms = useExitWarningContext();
  const dispatch = useExitWarningDispatchContext();
  const shouldBlockNavigation = dirtyForms.length > 0;
  const dirtyFormNames = dirtyForms.join(', ');

  // Stop user with default browser dialog when reloading/navigating away from the app
  const onBeforeUnload = useCallback(
    (ev: Event) => {
      if (shouldBlockNavigation) {
        ev.preventDefault();
      }
      return true;
    },
    [shouldBlockNavigation],
  );
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [onBeforeUnload]);

  // Stop user when navigating away inside the app, using custom Router Prompt
  // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const history = useHistory();
  const leavePage = () => setConfirmedNavigation(true);
  const stayOnPage = () => {
    setConfirmedNavigation(false);
    setIsModalOpen(false);
  };
  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && shouldBlockNavigation) {
      setIsModalOpen(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previously blocked location
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  // Return the array of dirty forms to it's initial state when the the component unmounts.
  useEffect(
    () => () => {
      dispatch({
        type: ReducerActionKind.removeAll,
      });
    },
    [dispatch],
  );

  return (
    <>
      <Prompt when={shouldBlockNavigation} message={handleBlockedNavigation} />
      <Modal.Root open={isModalOpen}>
        <Modal.Content
          showCloseButton={false}
          width={{
            '@initial': 640,
            '@mobile': '100%',
            '@mobileLandscape': '100%',
          }}
        >
          <StackLayout
            horizontalAlignment="end"
            css={{ padding: '$half $half $none $half' }}
          >
            <Button
              priority="tertiary"
              icon="tinyClose"
              size="small"
              hideLabel
              quiet
              onClick={stayOnPage}
            >
              {stayButtonText ||
                formatMessage({ id: 'STR_EXIT_WARNING_STAY_ON_PAGE' })}
            </Button>
          </StackLayout>
          <ImageMessage image={EXIT} imageGap="$two" imageWidth={160}>
            <StackLayout>
              <Modal.Title>
                <HeadingText
                  size="xl"
                  level="2"
                  css={{ marginBlockEnd: '$half' }}
                >
                  {headingText ||
                    formatMessage({ id: 'STR_EXIT_WARNING_HEADING' })}
                </HeadingText>
              </Modal.Title>
              <Modal.Description>
                <ParagraphText>
                  {bodyText ||
                    formatMessage(
                      { id: 'STR_EXIT_WARNING_BODY' },
                      { formName: dirtyFormNames },
                    )}
                </ParagraphText>
              </Modal.Description>
              <ResponsiveButtonRow
                layoutProps={{ horizontalAlignment: 'spaceBetween' }}
                css={{
                  marginBlockStart: '$oneAndHalf',
                }}
                primaryBtn={
                  <Modal.Close>
                    <Button
                      width={isBelowTablet ? 'fillContainer' : 'hugContents'}
                      onClick={leavePage}
                    >
                      {leaveButtonText ||
                        formatMessage({ id: 'STR_EXIT_WARNING_LEAVE_PAGE' })}
                    </Button>
                  </Modal.Close>
                }
                secondaryBtn={
                  <Button
                    priority="secondary"
                    width={isBelowTablet ? 'fillContainer' : 'hugContents'}
                    onClick={stayOnPage}
                  >
                    {stayButtonText ||
                      formatMessage({ id: 'STR_EXIT_WARNING_STAY_ON_PAGE' })}
                  </Button>
                }
              />
            </StackLayout>
          </ImageMessage>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
