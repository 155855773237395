import { SkeletonRectangle, Box } from '@leagueplatform/genesis-core';
import { PAGE_NAVIGATION_HEIGHT } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import { scanHealthTheme } from 'theme/scan-theme';

export const GenericSkeletonPage = () => {
  const isBelowTablet = useIsBelowTablet();

  const PAGE_HEADER_HEIGHT = isBelowTablet ? 96 : 140;
  return (
    <Box
      css={{
        display: 'grid',
        minHeight: `calc(100vh - ${PAGE_NAVIGATION_HEIGHT}px)`,
        gridGap: '$three',
        gridTemplateRows: `${PAGE_HEADER_HEIGHT}px 1fr`,
      }}
    >
      {/* Page Header */}
      <SkeletonRectangle height={PAGE_HEADER_HEIGHT} />

      {/* Page Content */}
      <Box
        css={{
          display: 'grid',
          gridTemplateRows: 'repeat(3, 1fr)',
          gridGap: '$oneAndHalf',
          maxHeight: `calc(100vh - ${
            PAGE_NAVIGATION_HEIGHT +
            PAGE_HEADER_HEIGHT +
            scanHealthTheme.spacing.five
          }px)`,
          paddingInline: '$two',
          marginInline: '$three',
          '@mobile': {
            paddingInline: '$none',
            marginInline: '$one',
          },
          '@mobileLandscape': {
            paddingInline: '$none',
            marginInline: '$one',
          },
        }}
      >
        <SkeletonRectangle borderRadius="$card" />
        <SkeletonRectangle borderRadius="$card" />
        <SkeletonRectangle borderRadius="$card" />
      </Box>
    </Box>
  );
};
