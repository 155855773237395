import React, { useEffect } from 'react';
import { useActorRef } from '@xstate/react';

import { pbpChangeMachine } from './state-machine/pbp-change-machine';
import { ActorRefContext } from './actor-ref-context';
import { StateViewer } from './components/state-viewer';
import { View } from './components/view';

export const PBPChange = () => {
  const actorRef = useActorRef(pbpChangeMachine);

  useEffect(() => {
    const unsubscribe = actorRef.subscribe((snapshot) => {
      console.log(`machine snapshot update`, snapshot);
    });

    return () => {
      unsubscribe.unsubscribe();
    };
  }, [actorRef]);

  return (
    <ActorRefContext.Provider value={actorRef}>
      <View />

      {/* TODO remove this, it's only for debugging */}
      <StateViewer />
    </ActorRefContext.Provider>
  );
};
