import { StackLayout, styled } from '@leagueplatform/genesis-core';
import { CTA_FOOTER_HEIGHT } from 'common/constants/ui';

const mobileFullPageStyle = {
  margin: '$none',
  boxShadow: '$none',
  paddingBlockEnd: `calc($twoAndHalf + ${CTA_FOOTER_HEIGHT})`,
  justifyContent: 'center',
};

export const ContentSection = styled(StackLayout, {
  backgroundColor: '$surfaceCardSecondary',
  padding: '$twoAndHalf',
  boxShadow: '$card',
  margin: 'auto',
});

export const FullPageContentSection = styled(ContentSection, {
  '@mobileLandscape': mobileFullPageStyle,
  '@mobile': mobileFullPageStyle,
});
