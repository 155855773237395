export const API_PATHS = {
  COMMUNICATION_PREFERENCES: '/v1/communication-preferences',
  SELECT_OPTIONS: 'v1/menu',
};

export const QUERY_KEYS = {
  COMMUNICATION_PREFERENCES: 'COMMUNICATION-PREFERENCES',
  SELECT_OPTIONS: 'SELECT_OPTIONS',
};

export const MUTATION_KEYS = {
  COMMUNICATION_PREFERENCES: {
    PATCH: 'PATCH-COMMUNICATION-PREFERENCES',
  },
};
