import React, { Fragment, useCallback } from 'react';
import {
  Button,
  Card,
  Divider,
  HeadingText,
  StackItem,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { useSelector } from '../hooks/useSelector';
import { useSend } from '../hooks/useSend';
import { assertDefined } from '../utils';

const ReviewSection = styled(Card.Elevated, {
  margin: '$one',
  padding: '$one',
});

const Plan = () => {
  const { currentPlan, selectedPlan, selectedEnrollmentPeriod } = useSelector(
    (snapshot) => snapshot.context,
  );

  return (
    <ReviewSection>
      <HeadingText level="2" size="lg">
        Plan information
      </HeadingText>
      <StackLayout>
        <StackItem>Changing plan from: {currentPlan!.planName}</StackItem>
        <Divider />
        <StackItem>Changing plan to: {selectedPlan!.planName}</StackItem>
        <Divider />
        <StackItem>premium you will pay: {selectedPlan!.premium}</StackItem>
        <StackItem>
          Plan effective date: {selectedEnrollmentPeriod!.effectiveDate}
        </StackItem>
      </StackLayout>
    </ReviewSection>
  );
};

const Eligibility = () => {
  const { selectedPlan, selectedEnrollmentPeriod, userId } = useSelector(
    (snapshot) => snapshot.context,
  );

  return (
    <ReviewSection>
      <HeadingText level="2" size="lg">
        Eligibility information
      </HeadingText>
      <StackLayout>
        <StackItem>Member ID: {userId}</StackItem>
        <Divider />
        <StackItem>
          Enrolling the new plan in: {selectedEnrollmentPeriod!.enrollmentYear}
        </StackItem>
        {selectedEnrollmentPeriod!.answers?.map((answer) => (
          <Fragment key={answer.id}>
            <Divider />
            <StackItem>Reasoning for plan change: {answer.label}</StackItem>
            {answer.requiresDate && (
              <StackItem>
                {answer.dateLabel!}: {answer.questionDate!}
              </StackItem>
            )}
          </Fragment>
        ))}
        {selectedPlan!.answers && (
          <>
            <HeadingText level="2" size="lg">
              Additional eligibility for {selectedPlan!.planName} plan
            </HeadingText>
            {selectedPlan!.answers.map((answer, index) => {
              assertDefined(answer.questionLabel);

              return (
                <Fragment key={answer.questionId}>
                  <StackItem>
                    {answer.questionLabel}:{' '}
                    {answer.options.map((option) => option.label).join(',')}
                  </StackItem>
                  {index < selectedPlan!.answers!.length - 1 && <Divider />}
                </Fragment>
              );
            })}
          </>
        )}
      </StackLayout>
    </ReviewSection>
  );
};

const Address = () => {
  const { addressInformation } = useSelector((snapshot) => snapshot.context);

  return (
    <ReviewSection>
      <HeadingText level="2" size="lg">
        Address information
      </HeadingText>
      <StackLayout>
        {addressInformation ? (
          <>
            <StackItem>Are you moving?: Yes, I am moving</StackItem>
            <Divider />
            <StackItem>
              New residential address:{' '}
              {addressInformation.residential.toString()}
            </StackItem>
            <Divider />
            <StackItem>
              New mailing address:{' '}
              {addressInformation.mailing?.toString() ||
                'Same as residential address'}
            </StackItem>
          </>
        ) : (
          <StackItem>Are you moving?: No, I am not moving</StackItem>
        )}
      </StackLayout>
    </ReviewSection>
  );
};

const CareTeam = () => {
  const { selectedPCP, dentalInformation } = useSelector(
    (snapshot) => snapshot.context,
  );

  let pcp = (
    <StackItem>
      A doctor will be assigned to you as you did not select one
    </StackItem>
  );

  if (selectedPCP) {
    assertDefined(selectedPCP.providerName);
    assertDefined(selectedPCP.practiceAddress?.address);
    assertDefined(selectedPCP.providerContact?.contact);

    pcp = (
      <>
        <StackItem>PCP name: {selectedPCP.providerName.fullName}</StackItem>
        <Divider />
        <StackItem>
          PCP address: {selectedPCP.practiceAddress.address.toString()}
        </StackItem>
        <Divider />
        <StackItem>
          Phone number: {selectedPCP.providerContact.contact.phoneNumber}
        </StackItem>
      </>
    );
  }

  return (
    <ReviewSection>
      <HeadingText level="2" size="lg">
        Your care team
      </HeadingText>
      <HeadingText level="3" size="md">
        Primary care doctor (PCP)
      </HeadingText>
      <StackLayout>{pcp}</StackLayout>

      {dentalInformation && (
        <>
          <HeadingText level="3" size="md">
            Dental information
          </HeadingText>
          <StackLayout>
            <StackItem>
              Dental provider ID number:{' '}
              {dentalInformation.dentalProviderNumber}
            </StackItem>
            <Divider />
            <StackItem>
              Dental office gropu number: {dentalInformation.dentalOfficeName}
            </StackItem>
          </StackLayout>
        </>
      )}
    </ReviewSection>
  );
};

const Payment = () => {
  const { selectedPaymentOption } = useSelector((snapshot) => snapshot.context);
  return (
    <>
      {selectedPaymentOption && (
        <ReviewSection>
          <HeadingText level="2" size="lg">
            Payment information
          </HeadingText>
          <StackLayout>
            <StackItem>
              How do you plan to pay for your plan premiums?:{' '}
              {selectedPaymentOption.optionLabel}
            </StackItem>
            {selectedPaymentOption.optionQuestion && (
              <>
                <Divider />
                <StackItem>
                  I get benefits form:{' '}
                  {selectedPaymentOption.optionQuestion.optionQuestionLabel}
                </StackItem>
              </>
            )}
          </StackLayout>
        </ReviewSection>
      )}
    </>
  );
};

const Communication = () => {
  const { selectedCommunicationOption } = useSelector(
    (snapshot) => snapshot.context,
  );
  return (
    <>
      {selectedCommunicationOption && (
        <ReviewSection>
          <HeadingText level="2" size="lg">
            Communication from SCAN Health
          </HeadingText>
          <StackLayout>
            <StackItem>
              Do you want to receive our materials through an accessible
              format?: {selectedCommunicationOption.optionLabel}
            </StackItem>
          </StackLayout>
        </ReviewSection>
      )}
    </>
  );
};

/**
 * Shown when the user is ready to review their input.
 */
export const ReviewView = () => {
  const send = useSend();

  const continueClickHandler = useCallback(() => {
    send({ type: 'continue' });
  }, [send]);
  return (
    <>
      <Plan />
      <Eligibility />
      <Address />
      <CareTeam />
      <Payment />
      <Communication />
      <Button type="button" onClick={continueClickHandler}>
        Continue
      </Button>
    </>
  );
};
