import React, { useCallback } from 'react';
import {
  ControlledFieldset,
  FormProvider,
  useWatch,
} from '@leagueplatform/web-common';
import { Button, Radio } from '@leagueplatform/genesis-core';
import { useSelector } from '../hooks/useSelector';
import { useSend } from '../hooks/useSend';
import { PaymentOption } from '../types';
import { formatPaymentOptionForContext } from '../utils';

type FormFieldValues = {
  paymentOption: string;
  paymentOptionQuestion?: string;
};

type FieldsProps = {
  paymentOptions: PaymentOption[];
};
const Fields = ({ paymentOptions }: FieldsProps) => {
  const selectedPaymentOption = useWatch<FormFieldValues>({
    name: 'paymentOption',
  });

  // TODO: figure out how this data shape even makes sense
  const paymentOptionQuestions = paymentOptions.find(
    ({ optionValue }) => optionValue === selectedPaymentOption,
  )?.optionQuestions;

  return (
    <>
      <ControlledFieldset
        name="paymentOption"
        inputOptions={{ required: true }}
        legend="How do you want to pay for your plan premiums?"
      >
        {paymentOptions.map(({ optionLabel, optionValue }) => (
          <Radio
            key={optionValue}
            name="paymentOption"
            id={`paymentOption-${optionValue}`}
            label={optionLabel}
            value={optionValue}
          />
        ))}
      </ControlledFieldset>
      {paymentOptionQuestions?.length && (
        <ControlledFieldset
          name="paymentOptionQuestion"
          inputOptions={{ required: true }}
          legend="I get benefits from"
        >
          {paymentOptionQuestions.map(
            ({ optionQuestionLabel, optionQuestionValue }) => (
              <Radio
                key={optionQuestionValue}
                name="paymentOptionQuestion"
                id={`paymentOptionQuestion-${optionQuestionValue}`}
                label={optionQuestionLabel}
                value={optionQuestionValue}
              />
            ),
          )}
        </ControlledFieldset>
      )}
    </>
  );
};

/**
 * Shown when the user is ready to select payment options.
 */
export const PaymentInformationView = () => {
  const send = useSend();

  const paymentOptions = useSelector(
    (snapshot) => snapshot.context.paymentOptions!,
  );
  const submitHandler = useCallback(
    (fieldValues: FormFieldValues) => {
      send({
        type: 'submitPaymentOptionSelection',
        selectedPaymentOption: formatPaymentOptionForContext(
          paymentOptions,
          fieldValues.paymentOption,
          fieldValues.paymentOptionQuestion,
        ),
      });
    },
    [paymentOptions, send],
  );

  return (
    <FormProvider<FormFieldValues>
      formOptions={{ mode: 'onSubmit', shouldUnregister: true }}
      onSubmit={submitHandler}
    >
      <Fields paymentOptions={paymentOptions} />
      <Button type="submit">Submit</Button>
    </FormProvider>
  );
};
