import React, { useCallback, useMemo } from 'react';
import {
  FormProvider,
  useFormContext,
  useInput,
} from '@leagueplatform/web-common';
import { RHFTextInput } from 'components/form-elements/rhf-text-input.component';
import { Button, Checkbox } from '@leagueplatform/genesis-core';
import { useSend } from '../hooks/useSend';

type FormFieldValues = {
  firstName: string;
  lastName: string;
  firstNameConfirm: string;
  lastNameConfirm: string;
  acknowledgement: boolean;
};

const Fields = () => {
  const { field, inputValidationState } = useInput('acknowledgement', {
    required: true,
  });

  const { getValues } = useFormContext<FormFieldValues>();

  const [validateFirstNameConfig, validateLastNameConfig] = useMemo(() => {
    const validate = (
      fieldValue: string,
      otherFieldName: 'firstName' | 'lastName',
      otherFieldLabel: string,
    ) => {
      const otherFieldValue = getValues(otherFieldName);

      return fieldValue !== otherFieldValue
        ? `Must match ${otherFieldLabel}`
        : undefined;
    };

    return [
      (fieldValue: string) => validate(fieldValue, 'firstName', 'First name'),
      (fieldValue: string) => validate(fieldValue, 'lastName', 'Last name'),
    ];
  }, [getValues]);

  return (
    <>
      <RHFTextInput id="firstName" label="First name" required />
      <RHFTextInput
        id="firstNameConfirm"
        label="Re-type first name"
        required
        registerOptions={{ validate: validateFirstNameConfig }}
      />
      <RHFTextInput id="lastName" label="Last name" required />
      <RHFTextInput
        id="lastNameConfirm"
        label="Re-type last name"
        required
        registerOptions={{ validate: validateLastNameConfig }}
      />
      <Checkbox
        id="acknowledgement"
        {...field}
        {...inputValidationState}
        label="I understand by checking this box, I acknowledge the information above and I am submitting my plan change request to SCAN."
      />
    </>
  );
};

/**
 * Shown when the user is ready to sign and submit.
 */
export const EsignView = () => {
  const send = useSend();

  const submitHandler = useCallback(
    (fieldValues: FormFieldValues) => {
      send({ type: 'submitEsignature', esignatureData: fieldValues });
    },
    [send],
  );

  return (
    <FormProvider formOptions={{ mode: 'onSubmit' }} onSubmit={submitHandler}>
      <Fields />
      <Button type="submit">Submit</Button>
    </FormProvider>
  );
};
