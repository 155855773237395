import React, { useState, useEffect } from 'react';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import {
  Box,
  HeadingText,
  StackLayout,
  TextAction,
  UtilityText,
} from '@leagueplatform/genesis-core';
import { Switch } from '@leagueplatform/web-common-components';
import {
  usePageViewAnalytics,
  SCREEN_NAMES,
  PRODUCT_AREA,
  trackAnalyticsEvent,
  EVENT_NAME,
} from '@leagueplatform/analytics';
import { Link } from '@leagueplatform/routing';
import { PageHeader } from 'components/page-header/page-header.component';
import { ContentSection } from 'components/content-section/content-section.component';
import { TextCommunicationTermsModal } from 'components/modals/text-communication-terms/text-communication-terms.component';

import { PAGE_HEADER_MAX_WIDTH } from 'common/constants/ui';
import { ROUTE_PATHS } from 'common/constants/constants';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

const TextNotificationsLabel = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <UtilityText
        css={{
          display: 'block',
        }}
        emphasis="emphasized"
      >
        {formatMessage({ id: 'STR_ALLOW_TEXT_NOTIFICATIONS' })}
      </UtilityText>
      <UtilityText>{formatMessage({ id: 'STR_RECEIVE_TEXTS' })}</UtilityText>
    </>
  );
};

export const CommunicationPreferencesPage = () => {
  const isBelowTablet = useIsBelowTablet();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' }));
  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
  });

  // TODO [SCAN-767][API Integration] get value from API and set the switch default state.
  const [textNotificationSwitch, setTextNotificationSwitch] =
    useState<boolean>(false);
  const [
    isTextCommunicationTermsModalOpen,
    setIsTextCommunicationTermsModalOpen,
  ] = useState<boolean>(false);

  const handleToggleTextNotification = () => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
      detail: 'text notification toggle',
      toggle_status: !textNotificationSwitch ? 'on' : 'off',
    });

    // Open the modal when the switch goes from false to true
    if (textNotificationSwitch === false) {
      setIsTextCommunicationTermsModalOpen(true);
    }

    setTextNotificationSwitch((prev: boolean) => !prev);
  };

  useEffect(() => {
    // TODO [SCAN-767][API Integration] send mutation when the user toggles the switch both on and off.
  }, [textNotificationSwitch]);

  return (
    <Box
      css={{
        display: 'grid',
        gridGap: '$three',
        gridTemplateRows: 'auto 1fr',
        paddingBlockEnd: isBelowTablet ? undefined : '$three',
      }}
    >
      <PageHeader
        text={formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' })}
      />
      <Box css={{ paddingInline: '$two' }}>
        <Box
          css={{
            display: isBelowTablet ? 'flex' : 'grid',
            flexDirection: isBelowTablet ? 'column' : undefined,
            gridTemplateColumns: '1fr 30%',
            justifyContent: 'start',
            gap: '$oneAndHalf',
            alignItems: isBelowTablet ? 'stretch' : 'start',
            maxWidth: PAGE_HEADER_MAX_WIDTH,
            marginInline: 'auto',
          }}
        >
          <ContentSection
            css={{
              paddingInlineStart: '$two',
              height: 'auto',
              margin: 0,
            }}
            horizontalAlignment="stretch"
          >
            <StackLayout spacing="$half" css={{ height: 'auto' }}>
              <HeadingText
                level="2"
                size="md"
                css={{ paddingInlineStart: '$half' }}
              >
                Notifications
              </HeadingText>
              <Switch
                id="29293"
                label={<TextNotificationsLabel />}
                checked={textNotificationSwitch}
                layout="row"
                onChange={handleToggleTextNotification}
                hideIcon
              />
            </StackLayout>
          </ContentSection>

          <ContentSection
            css={{ height: 'auto', margin: 0 }}
            horizontalAlignment="stretch"
          >
            <StackLayout spacing="$half" css={{ height: 'auto' }}>
              <HeadingText level="2" size="md">
                {formatMessage({ id: 'STR_DOCUMENT_PREFERENCES' })}
              </HeadingText>
              <TextAction
                as={Link}
                to={ROUTE_PATHS.DOCUMENT_PREFERENCES()}
                icon="illustrativeMobileAlertUser"
                indicator="disclosure"
                css={{
                  alignItems: 'start',
                  gap: '$half',
                  '.GDS-icon': {
                    marginBlockStart: '$half',
                    width: 40,
                  },
                  '@mobile': {
                    gap: '$quarter',
                  },
                }}
              >
                {formatMessage({ id: 'STR_MANAGE_DOCUMENT_PREFERENCES' })}
              </TextAction>
            </StackLayout>
          </ContentSection>
        </Box>
      </Box>
      <TextCommunicationTermsModal
        open={isTextCommunicationTermsModalOpen}
        closeModal={(isTextCommunicationTermsAccepted: boolean) => {
          setIsTextCommunicationTermsModalOpen(false);
          setTextNotificationSwitch(isTextCommunicationTermsAccepted);
        }}
      />
    </Box>
  );
};
