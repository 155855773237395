import { type PropsWithChildren } from 'react';
import { LoadingIndicator } from '@leagueplatform/web-common-components';
import OnboardingRoutesWithProvider from '@leagueplatform/onboarding';
import { AccountSetupPage } from 'pages/account-setup/account-setup.page';
import { AccountSetupCompletePage } from 'pages/account-setup/account-setup-complete.page';
import { useOnboarding } from 'hooks/use-onboarding/use-onboarding.hook';
import { BasePage } from 'components/base-page.component';
import { TENANT_ID } from 'common/constants/constants';

export const PrivateRoutesContainer = ({ children }: PropsWithChildren) => {
  const {
    showFeatureHighlights,
    isLoading,
    onFeatureHighlightsComplete,
    showAccountSetup,
    showAccountSetupCompleted,
    onCompleteAccountSetup,
    onCompleteOnboarding,
  } = useOnboarding();

  if (isLoading) return <LoadingIndicator />;

  return <BasePage>{children}</BasePage>;

  if (showFeatureHighlights)
    return (
      <OnboardingRoutesWithProvider
        contentfulId={import.meta.env.VITE_ONBOARDING_CONTENTFUL_ID}
        tenantId={TENANT_ID}
        onOnboardingComplete={onFeatureHighlightsComplete}
      />
    );

  if (showAccountSetup && !showAccountSetupCompleted)
    return <AccountSetupPage onCompleteAccountSetup={onCompleteAccountSetup} />;

  if (showAccountSetupCompleted)
    return (
      <AccountSetupCompletePage onOnboardingComplete={onCompleteOnboarding} />
    );
};
