import { keyframes } from '@leagueplatform/genesis-core';

export const translateInBottom = keyframes({
  '0%': { transform: 'translateY(100%)' },
  '100%': { transform: 'translateY(0)' },
});

export const translateOutBottom = keyframes({
  '0%': { transform: 'translateY(0%)' },
  '100%': { transform: 'translateY(100%)' },
});
