import type {
  LocalesKey,
  FooterLink,
  AppStoreLinkProps,
} from 'common/types/types';
import APPLE_STORE_ICON from 'assets/apple-store-icon.png';
import GOOGLE_STORE_ICON from 'assets/google-store-icon.png';

export const FOOTER_LINK_LABELS: { [key: string]: LocalesKey } = {
  NON_DISCRIMINATION_POLICY: 'STR_NON_DISCRIMINATION_POLICY',
  PRIVACY_POLICY: 'STR_PRIVACY_POLICY',
  TERMS_OF_USE: 'STR_TERMS_OF_USE',
};

export const FOOTER_LINKS: FooterLink[] = [
  {
    to: '#',
    target: '_blank',
    text: FOOTER_LINK_LABELS.NON_DISCRIMINATION_POLICY,
  },
  {
    to: '#',
    target: '_blank',
    text: FOOTER_LINK_LABELS.PRIVACY_POLICY,
  },
  {
    to: '#',
    target: '_blank',
    text: FOOTER_LINK_LABELS.TERMS_OF_USE,
  },
];

export const FOOTER_APP_STORE_LINKS: AppStoreLinkProps[] = [
  {
    href: '#',
    alt: 'STR_DOWNLOAD_APP_STORE',
    src: APPLE_STORE_ICON,
  },
  {
    href: '#',
    alt: 'STR_GET_GOOGLE_PLAY',
    src: GOOGLE_STORE_ICON,
  },
];
