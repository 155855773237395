import React, {
  useState,
  useCallback,
  type MouseEventHandler,
  useRef,
  useEffect,
  forwardRef,
} from 'react';
import {
  HeadingText,
  ParagraphText,
  Checkbox,
  Modal,
  Button,
  StackLayout,
  Box,
  UtilityText,
  InputStatusMessage,
  styled,
} from '@leagueplatform/genesis-core';
import { useForm, type FieldValues } from '@leagueplatform/web-common';
import {
  PRODUCT_AREA,
  trackAnalyticsEvent,
  EVENT_NAME,
  SCREEN_NAMES,
} from '@leagueplatform/analytics';
import { useIntl } from '@leagueplatform/locales';
import SignedDocumentIllustration from 'assets/signed-document-illustration.png';
import { ResponsiveButtonRow } from 'components/responsive-button-row/responsive-button-row.component';
import { TextCommunicationTermsContent } from 'components/modals/text-communication-terms/text-communication-terms-content.component';

interface TextCommunicationModalFormValues extends FieldValues {
  acceptTextCommunicationTerms: boolean;
}

type TextCommunicationTermsModalProps = {
  open?: boolean;
  closeModal: (userAcceptedTermsAndConditions: boolean) => void;
};

const TermsAndConditionButton = forwardRef<
  HTMLButtonElement,
  {
    onClick: MouseEventHandler<HTMLButtonElement>;
  }
>(({ onClick }, ref) => {
  const { formatMessage } = useIntl();

  return (
    <Button
      priority="tertiary"
      quiet
      css={{
        display: 'inline-block',
        padding: 0,
        textDecoration: 'underline',
      }}
      onClick={onClick}
      type="button"
      ref={ref}
    >
      {formatMessage({ id: 'STR_TERMS_AND_CONDITIONS' })}
    </Button>
  );
});

const ModalImage = styled(`img`, {
  alignSelf: 'center',
});

export const TextCommunicationTermsModal = ({
  open,
  closeModal,
}: TextCommunicationTermsModalProps) => {
  const [showTermsContent, setShowTermsContent] = useState<boolean>(false);
  const { register, handleSubmit, formState, setFocus, reset, watch } =
    useForm<TextCommunicationModalFormValues>({
      defaultValues: {
        acceptTextCommunicationTerms: false,
      },
    });
  const watchAcceptTermsCheckbox = watch('acceptTextCommunicationTerms');
  const { formatMessage } = useIntl();
  const handleBackButton = useCallback(() => setShowTermsContent(false), []);
  const closeModalWithoutAcceptance = useCallback(() => {
    closeModal(false);
    setShowTermsContent(false);
  }, [closeModal]);
  const closeModalWithAcceptance = useCallback(() => {
    trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
      product_area: PRODUCT_AREA.SETTINGS,
      screen_name: SCREEN_NAMES.TEXT_TERMS_AND_CONDITIONS,
      detail: 'accept text terms',
    });
    closeModal(true);
  }, [closeModal]);

  const showTermsContentRef = useRef<boolean>(false);
  const termsAndConditionsButton = useRef<HTMLButtonElement>(null);
  const backButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (showTermsContent === false && showTermsContentRef.current === true) {
      termsAndConditionsButton.current?.focus();
    }
    if (showTermsContent === true && showTermsContentRef.current === false) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.SETTINGS,
        screen_name: SCREEN_NAMES.TEXT_TERMS_AND_CONDITIONS,
      });
      backButtonRef.current?.focus();
    }
    showTermsContentRef.current = showTermsContent;
  }, [setFocus, showTermsContent]);

  // Send screen loaded analytics event and reset the checkbox value when the modal is opened.
  useEffect(() => {
    if (open) {
      trackAnalyticsEvent(EVENT_NAME.SCREEN_LOADED, {
        product_area: PRODUCT_AREA.SETTINGS,
        screen_name: SCREEN_NAMES.TEXT_TC_MODAL,
      });
      reset();
    }
  }, [open, reset]);

  // Send analytics event when the checkbox is selected
  useEffect(() => {
    if (watchAcceptTermsCheckbox === true)
      trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
        product_area: PRODUCT_AREA.SETTINGS,
        screen_name: SCREEN_NAMES.TEXT_TERMS_AND_CONDITIONS,
        detail: 'text terms checkbox',
      });
  }, [watchAcceptTermsCheckbox]);

  const { errors } = formState;

  return (
    <Modal.Root
      open={open}
      onOpenChange={(isOpen) => {
        if (!isOpen) closeModalWithoutAcceptance();
      }}
    >
      <Modal.Content
        showCloseButton={false}
        width={{
          '@initial': 639,
          '@mobile': '100%',
          '@mobileLandscape': '100%',
        }}
        data-testid="text-communication-modal"
      >
        <StackLayout spacing="$oneAndHalf" horizontalAlignment="stretch">
          <StackLayout
            orientation="horizontal"
            horizontalAlignment={showTermsContent ? 'spaceBetween' : 'end'}
          >
            {showTermsContent && (
              <Button
                onClick={handleBackButton}
                quiet
                priority="tertiary"
                icon="interfaceChevronLeft"
                size="medium"
                hideLabel
                ref={backButtonRef}
              >
                {formatMessage({ id: 'STR_GO_BACK' })}
              </Button>
            )}
            <Modal.Close>
              <Button
                onClick={closeModalWithoutAcceptance}
                quiet
                priority="tertiary"
                icon="interfaceClose"
                size="medium"
                hideLabel
              >
                {formatMessage({ id: 'STR_CLOSE' })}
              </Button>
            </Modal.Close>
          </StackLayout>

          <ModalImage src={SignedDocumentIllustration} aria-hidden alt="" />
          {!showTermsContent ? (
            <>
              <Box>
                <HeadingText
                  level="2"
                  size="md"
                  css={{
                    marginBlockEnd: '$quarter',
                  }}
                >
                  {formatMessage({ id: 'STR_TERMS_ACCEPTANCE_HEADING' })}
                </HeadingText>
                <ParagraphText>
                  {formatMessage(
                    { id: 'STR_TERMS_PLEASE_READ' },
                    {
                      termsAndConditionsButton: (
                        <TermsAndConditionButton
                          onClick={() => setShowTermsContent(true)}
                          ref={termsAndConditionsButton}
                        />
                      ),
                    },
                  )}
                </ParagraphText>
              </Box>
              <StackLayout
                as="form"
                onSubmit={handleSubmit(closeModalWithAcceptance)}
                spacing="$two"
              >
                <span>
                  <Checkbox
                    id="accept-text-communication-terms-checkbox"
                    label={
                      <UtilityText css={{ fontWeight: '$semibold' }}>
                        {formatMessage({ id: 'STR_SELECT_CHECKBOX_AGREE' })}
                      </UtilityText>
                    }
                    {...register('acceptTextCommunicationTerms', {
                      required: {
                        value: true,
                        message: formatMessage({
                          id: 'STR_TERMS_REQUIRED_ERROR',
                        }),
                      },
                    })}
                  />
                  {errors?.acceptTextCommunicationTerms !== undefined && (
                    <InputStatusMessage
                      hideIcon
                      id="accept-text-communication-terms-checkbox"
                      inputStatus="error"
                    >
                      {errors.acceptTextCommunicationTerms.message}
                    </InputStatusMessage>
                  )}
                </span>

                <ResponsiveButtonRow
                  primaryBtn={
                    <Button type="submit" width="fillContainer">
                      {formatMessage({ id: 'STR_ACCEPT_TERMS' })}
                    </Button>
                  }
                  secondaryBtn={
                    <Modal.Close>
                      <Button
                        onClick={() => {
                          trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
                            product_area: PRODUCT_AREA.SETTINGS,
                            screen_name: SCREEN_NAMES.TEXT_TERMS_AND_CONDITIONS,
                            detail: 'reject text terms',
                          });

                          closeModalWithoutAcceptance();
                        }}
                        priority="secondary"
                        width="fillContainer"
                      >
                        {formatMessage({ id: 'STR_REJECT_TERMS' })}
                      </Button>
                    </Modal.Close>
                  }
                />
              </StackLayout>
            </>
          ) : (
            <TextCommunicationTermsContent />
          )}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
