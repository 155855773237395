import { useIntl } from '@leagueplatform/locales';
import {
  HeadingText,
  StackLayout,
  Icon,
  Modal,
  Divider,
  Box,
  Button,
} from '@leagueplatform/genesis-core';
import { useLocation, NavLink } from '@leagueplatform/routing';
import {
  sendCapabilityNavLinkEvent,
  sendUserOptionEvent,
} from 'common/utils/analytics-events';
import {
  MobileNavLink,
  defaultMobileNavLinkStyles,
} from 'components/header-nav/mobile-nav/mobile-nav.links.component';
import {
  NavLink as NavLinkInterface,
  useNavLinksList,
} from 'hooks/use-nav-links-list/use-nav-links-list.hook';
import { useUserDropdownComponent } from 'hooks/use-user-dropdown/use-user-dropdown.hook';

type MobileNavProps = {
  closeModal: () => void;
  open: boolean;
  onOpenChange: () => void;
};

export const MobileNav = ({
  closeModal,
  open,
  onOpenChange,
}: MobileNavProps) => {
  const { formatMessage } = useIntl();
  const { navLinks } = useNavLinksList();
  const location = useLocation();
  const handleCapabilityNavLinkClick = (action: string) => {
    closeModal();
    sendCapabilityNavLinkEvent(action);
  };
  const mainNavLinks = navLinks.map(
    ({ message_id, icon, to }: NavLinkInterface) => {
      const isActiveLink = location.pathname.startsWith(to as string);
      return (
        <Box
          as={NavLink}
          to={to as string}
          id={`${formatMessage({ id: message_id })}-link`}
          key={`${formatMessage({ id: message_id })}-link`}
          css={defaultMobileNavLinkStyles}
          onClick={() => handleCapabilityNavLinkClick(message_id)}
        >
          {icon && (
            <Icon
              icon={isActiveLink ? `${icon}Filled` : icon}
              css={{ marginInlineEnd: '$half' }}
            />
          )}
          {formatMessage({ id: message_id })}
        </Box>
      );
    },
  );

  const {
    data: { menuItemsConfig: userDropdownItems },
  } = useUserDropdownComponent();

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Trigger>
        <Button
          icon="interfaceMenu"
          hideLabel
          quiet
          priority="tertiary"
          size="large"
          css={{
            margin: '$half $quarter',
          }}
          aria-controls={undefined}
        >
          {formatMessage({ id: 'STR_OPEN_MENU' })}
        </Button>
      </Modal.Trigger>
      <Modal.Content
        layout="right"
        css={{
          '.GDS-modal': { maxWidth: 335 },
          '.GDS-modal-content': { paddingInlineStart: '$one' },
        }}
      >
        <StackLayout as="nav" spacing="$one" horizontalAlignment="stretch">
          <Modal.Title>
            <HeadingText
              level="2"
              size="xl"
              css={{
                paddingInlineStart: '$half',
              }}
            >
              {formatMessage({ id: 'STR_MENU' })}
            </HeadingText>
          </Modal.Title>
          <StackLayout as="ul" spacing="$half" horizontalAlignment="stretch">
            {mainNavLinks}
          </StackLayout>
          {userDropdownItems.map(({ heading, items }) => (
            <StackLayout
              spacing="$oneAndHalf"
              css={{
                width: '100%',
              }}
              key={`mobile_nav--${heading || items[0]?.text}`}
              horizontalAlignment="stretch"
            >
              <Divider
                css={{ margin: '0 $half', width: 'calc(100% - $half)' }}
              />
              <StackLayout spacing="$half" horizontalAlignment="stretch">
                {heading && (
                  <HeadingText
                    level="3"
                    size="xl"
                    css={{
                      paddingInlineStart: '$half',
                    }}
                  >
                    {heading}
                  </HeadingText>
                )}
                <StackLayout
                  as="ul"
                  spacing="$half"
                  horizontalAlignment="stretch"
                >
                  {items.map((item) => {
                    const { text } = item;
                    const handleOptionClick: (event?: MouseEvent) => void = (
                      event,
                    ) => {
                      closeModal();
                      sendUserOptionEvent(text);
                      if (item?.onClick && event) item.onClick(event);
                    };

                    return (
                      <MobileNavLink
                        linkData={item}
                        onLinkClick={handleOptionClick}
                        key={`mobile-nav-link--${text}`}
                      >
                        {text}
                      </MobileNavLink>
                    );
                  })}
                </StackLayout>
              </StackLayout>
            </StackLayout>
          ))}
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
