import { useMutation } from 'react-query';
import { API_PATHS, MUTATION_KEYS } from 'common/constants/api';
import { leagueFetch } from '@leagueplatform/league-fetch';
import { components } from 'api/gen/communication-preferences';

export type CommunicationPreferencesSubmitVariables = {
  [
    key: string
  ]: components['schemas']['communicationMethods']['communicationMethodCode'];
};

export const usePatchCommunicationPreferences = () =>
  useMutation(
    async (variables: CommunicationPreferencesSubmitVariables) => {
      const response = await leagueFetch(API_PATHS.COMMUNICATION_PREFERENCES, {
        method: 'PATCH',
        // TODO [SCAN-819] Review once staging APIs are available
        body: JSON.stringify(variables),
      });

      if (!response?.ok) {
        throw new Error(
          `Error Response Code ${response?.status}: ${await response.text()}`,
        );
      }
    },
    {
      mutationKey: [MUTATION_KEYS.COMMUNICATION_PREFERENCES.PATCH],
    },
  );
