import { useEffect, useMemo } from 'react';
import { useFormContext, useGetUserProfile } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { RHFTextInput } from 'components/form-elements/rhf-text-input.component';
import { RHFSelectInput } from 'components/form-elements/rhf-select-input.component';
import { useGooglePlacesAutocomplete } from 'hooks/use-google-places-autocomplete/use-google-places-autocomplete.hook';
import {
  STATE_ABBREVIATIONS,
  ADDRESS_FORM_ELEMENT_IDS,
} from 'common/constants/constants';
import { useQueryClient } from 'react-query';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  UserProfileAggregate,
} from '@leagueplatform/user-profile-api';

const STATE_ABBREVIATION_OPTIONS = STATE_ABBREVIATIONS.map((abbreviation) => ({
  value: abbreviation,
}));

type AddressFormProps = {
  /**
   * Used to prefix the form element ids to avoid duplicate ids.
   */
  addressType: string;
  /**
   * Require the effective date field.
   */
  includeEffectiveDate?: boolean;
  includeZipCode?: boolean;
  includeCounty?: boolean;
};

export const AddressForm = ({
  addressType,
  includeEffectiveDate,
  includeZipCode,
  includeCounty,
}: AddressFormProps) => {
  const { formatMessage } = useIntl();
  const { unregister } = useFormContext();
  const PREFIXED_ADDRESS_IDS = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(ADDRESS_FORM_ELEMENT_IDS).map(([key, value]) => [
          key,
          `${addressType}_${value}`,
        ]),
      ),
    [addressType],
  );

  const {
    streetAddressInputRef,
    streetAddress2InputRef,
    cityInputRef,
    countyInputRef,
    stateInputRef,
    zipCodeInputRef,
  } = useGooglePlacesAutocomplete(PREFIXED_ADDRESS_IDS.STREET_ADDRESS);

  // Unregister the fields when the component unmounts
  useEffect(
    () => () =>
      Object.values(PREFIXED_ADDRESS_IDS).forEach((id) => unregister(id)),
    [PREFIXED_ADDRESS_IDS, unregister],
  );

  /**
   * We're not using the data returned by this query in rendering this component,
   * but we do want to benefit from its cached values; so we call this hook
   * as an indication that we want the query to run.
   *
   * Later, in the validation function for the State field, we will await
   * this query's data.
   */
  useGetUserProfile({ notifyOnChangeProps: [] });

  const queryClient = useQueryClient();

  const stateFieldRegisterOptions = useMemo(
    () => ({
      validate: async (value: string) => {
        const {
          user_profile: { locations },
        } = await queryClient.fetchQuery<UserProfileAggregate>([
          GET_USER_PROFILE_MESSAGE_TYPE,
          '',
        ]);

        const state = locations?.find((location) => location.isDefault)?.state;

        return state && value !== state
          ? formatMessage({ id: 'STR_STATE_HINT' })
          : undefined;
      },
    }),

    [formatMessage, queryClient],
  );

  return (
    <>
      <RHFTextInput
        id={PREFIXED_ADDRESS_IDS.STREET_ADDRESS}
        ref={streetAddressInputRef}
        label={formatMessage({ id: 'STR_STREET_ADDRESS' })}
        required
      />
      <RHFTextInput
        id={PREFIXED_ADDRESS_IDS.STREET_ADDRESS_2}
        ref={streetAddress2InputRef}
        label={formatMessage({ id: 'STR_STREET_ADDRESS_2' })}
      />
      <RHFTextInput
        id={PREFIXED_ADDRESS_IDS.CITY}
        ref={cityInputRef}
        label={formatMessage({ id: 'STR_CITY' })}
        required
      />
      {includeCounty && (
        <RHFTextInput
          id={PREFIXED_ADDRESS_IDS.COUNTY}
          ref={countyInputRef}
          label={formatMessage({ id: 'STR_COUNTY' })}
          required
        />
      )}
      <RHFSelectInput
        id={PREFIXED_ADDRESS_IDS.STATE}
        ref={stateInputRef}
        label={formatMessage({ id: 'STR_STATE' })}
        options={STATE_ABBREVIATION_OPTIONS}
        required
        disabled
        hint={formatMessage({ id: 'STR_STATE_HINT' })}
        registerOptions={stateFieldRegisterOptions}
      />
      {includeEffectiveDate && (
        <RHFTextInput
          type="date"
          id={PREFIXED_ADDRESS_IDS.EFFECTIVE_DATE}
          label={formatMessage({ id: 'STR_EFFECTIVE_DATE' })}
          required
        />
      )}
      {includeZipCode && (
        <RHFTextInput
          id={PREFIXED_ADDRESS_IDS.ZIP_CODE}
          ref={zipCodeInputRef}
          label={formatMessage({ id: 'STR_ZIP_CODE' })}
          required
        />
      )}
    </>
  );
};
