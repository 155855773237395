import type { PropsWithChildren } from 'react';
import { StackLayout } from '@leagueplatform/genesis-core';

export const ActionsList = ({ children }: PropsWithChildren<{}>) => (
  <StackLayout
    horizontalAlignment="stretch"
    css={{
      backgroundColor: '$surfaceBackgroundPrimary',
      padding: '$oneAndHalf',
      borderWidth: '$thin',
      borderStyle: 'solid',
      borderColor: '$onSurfaceBorderSubdued',
      borderRadius: '$large',
      flex: 0,
    }}
  >
    {children}
  </StackLayout>
);
