import { useRef, useEffect, useCallback } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useFormContext } from '@leagueplatform/web-common';

export const useGooglePlacesAutocomplete = (autocompleteInputId: string) => {
  const streetAddressInputRef = useRef<HTMLInputElement>(null);
  const streetAddress2InputRef = useRef<HTMLInputElement>(null);
  const cityInputRef = useRef<HTMLInputElement>(null);
  const countyInputRef = useRef<HTMLInputElement>(null);
  const stateInputRef = useRef<HTMLSelectElement>(null);
  const zipCodeInputRef = useRef<HTMLInputElement>(null);
  const placesLib = useMapsLibrary('places');
  const { setValue } = useFormContext();

  const updateAddressFieldValue = useCallback(
    (
      ref: React.RefObject<HTMLInputElement | HTMLSelectElement | null>,
      value: string = '',
    ) => {
      if (ref?.current !== null) {
        const { current } = ref;
        current.value = value;
        setValue(current.id, value);
      }
    },
    [setValue],
  );

  useEffect(() => {
    const autocompleteInput: HTMLInputElement | null = document.querySelector(
      `#${autocompleteInputId}`,
    );

    if (placesLib !== null && autocompleteInput !== null) {
      // Initialize autocomplete widget
      const autocomplete = new placesLib.Autocomplete(autocompleteInput, {
        componentRestrictions: { country: 'us' },
        fields: ['address_components'],
        types: ['street_address'],
      });

      const fillInAddress = () => {
        const { address_components: addressComponents } =
          autocomplete.getPlace();

        if (addressComponents !== undefined) {
          // Transform the place's address components into an object
          const addressComponentsObj: Record<
            google.maps.GeocoderAddressComponent['types'][number],
            string | undefined
          > = addressComponents.reduce(
            (componentAccumulator, addressComponent) => {
              const addressComponentTypes = addressComponent.types.reduce(
                (typesAccumulator, addressComponentType) => ({
                  ...typesAccumulator,
                  [addressComponentType]:
                    addressComponentType === 'administrative_area_level_1'
                      ? addressComponent.short_name
                      : addressComponent.long_name,
                }),
                {},
              );
              return { ...componentAccumulator, ...addressComponentTypes };
            },
            {},
          );

          const {
            street_number: streetNumber = ' ',
            route = ' ',
            locality,
            sublocality_level_1: sublocalityLevel1,
            administrative_area_level_1: administrativeAreaLevel1,
            administrative_area_level_2: administrativeAreaLevel2,
            postal_code: postalCode,
          } = addressComponentsObj;

          // Street Address
          updateAddressFieldValue(
            streetAddressInputRef,
            `${streetNumber} ${route}`.trim(),
          );

          // Street Address 2
          updateAddressFieldValue(streetAddress2InputRef);

          // City
          updateAddressFieldValue(cityInputRef, locality || sublocalityLevel1);

          // County
          updateAddressFieldValue(countyInputRef, administrativeAreaLevel2);

          // Zip Code
          updateAddressFieldValue(zipCodeInputRef, postalCode);

          // State
          updateAddressFieldValue(stateInputRef, administrativeAreaLevel1);
        }
      };

      const placesChangeListener = autocomplete.addListener(
        'place_changed',
        fillInAddress,
      );

      return () => {
        // Clean up
        window.google.maps.event.removeListener(placesChangeListener);
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }

    return undefined;
  }, [autocompleteInputId, placesLib, updateAddressFieldValue]);

  return {
    streetAddressInputRef,
    streetAddress2InputRef,
    cityInputRef,
    countyInputRef,
    stateInputRef,
    zipCodeInputRef,
  };
};
