/* eslint-disable import/no-extraneous-dependencies */
import { Suspense } from 'react';
import { Route, Switch } from '@leagueplatform/routing';
import { getModulePath, LEAGUE_MODULE_NAMES } from '@leagueplatform/core';
import { ROUTE_PATHS } from 'common/constants/constants';
import {
  LandingPage,
  CareCollectionsRoutes,
  CareDiscoveryRoutes,
  AboutMeRoutes,
  Home,
  WalletRoutes,
  AccountInfo,
} from 'components/lazy-routes.component';
import { AuthViewLogin, AuthViewLogout } from 'pages/auth/auth.pages';
import ErrorPage from 'pages/error-404.page';
import { PBPChange } from 'pages/pbp-change/pbp-change.page';
import { AuthorizedRoutes } from 'components/authorized-routes/authorized-routes.component';
import { PrivateRoutesContainer } from 'components/private-routes-container/private-routes-container';
import { GenericSkeletonPage } from 'components/skeleton-page/skeleton-page.component';
import { CommunicationPreferencesPage } from 'pages/communication-preferences/communication-preferences.page';
import { DocumentPreferencesPage } from 'pages/document-preferences/document-preferences.page';
import { AboutPage } from 'pages/about/about.page';

function AppRoutes() {
  return (
    <Switch>
      <Route path={ROUTE_PATHS.SIGN_IN} component={AuthViewLogin} />
      <Route path={ROUTE_PATHS.SIGN_OUT} component={AuthViewLogout} />
      <Route path="/" exact component={LandingPage} />
      <AuthorizedRoutes>
        <PrivateRoutesContainer>
          <Switch>
            <Route path={ROUTE_PATHS.HOME} component={Home} exact key="home" />
            <Route
              path={ROUTE_PATHS.ACCOUNT_INFO}
              render={() => (
                <Suspense fallback={<GenericSkeletonPage />}>
                  <AccountInfo />
                </Suspense>
              )}
              exact
            />
            <Route
              path={ROUTE_PATHS.COMMUNICATION_PREFERENCES}
              component={CommunicationPreferencesPage}
              exact
            />
            <Route path={ROUTE_PATHS.ABOUT} component={AboutPage} exact />
            <Route
              path={ROUTE_PATHS.DOCUMENT_PREFERENCES()}
              render={() => (
                <Suspense fallback={<GenericSkeletonPage />}>
                  <DocumentPreferencesPage />
                </Suspense>
              )}
              exact
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careCollections)}
              component={CareCollectionsRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.careDiscovery)}
              component={CareDiscoveryRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.wallet)}
              component={WalletRoutes}
            />
            <Route
              path={getModulePath(LEAGUE_MODULE_NAMES.aboutMe)}
              component={AboutMeRoutes}
            />
            <Route path="/pbp-change" component={PBPChange} />
            <Route component={ErrorPage} />
          </Switch>
        </PrivateRoutesContainer>
      </AuthorizedRoutes>
    </Switch>
  );
}

export default AppRoutes;
