import {
  createContext,
  useContext,
  useReducer,
  type ReactNode,
  type Dispatch,
} from 'react';

type DirtyForms = string[];
export enum ReducerActionKind {
  add = 'add',
  remove = 'remove',
  removeAll = 'removeAll',
}
type ReducerAction =
  | {
      type: ReducerActionKind.add | ReducerActionKind.remove;
      heading: string;
    }
  | {
      type: ReducerActionKind.removeAll;
    };

const initialState: DirtyForms = [];
const exitWarningReducer = (dirtyForms: DirtyForms, action: ReducerAction) => {
  switch (action.type) {
    case 'add': {
      if (!dirtyForms.some((dirtyForm) => dirtyForm === action.heading)) {
        return [...dirtyForms, action.heading];
      }
      return dirtyForms;
    }
    case 'remove': {
      return dirtyForms.filter((dirtyForm) => dirtyForm !== action.heading);
    }
    case 'removeAll': {
      return initialState;
    }
    default: {
      return dirtyForms;
    }
  }
};
const ExitWarningContext = createContext<DirtyForms>(initialState);
const ExitWarningDispatchContext = createContext<Dispatch<ReducerAction>>(
  () => {},
);

export const ExitWarningContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [tasks, dispatch] = useReducer(exitWarningReducer, initialState);

  return (
    <ExitWarningContext.Provider value={tasks}>
      <ExitWarningDispatchContext.Provider value={dispatch}>
        {children}
      </ExitWarningDispatchContext.Provider>
    </ExitWarningContext.Provider>
  );
};

export function useExitWarningContext() {
  return useContext(ExitWarningContext);
}

export function useExitWarningDispatchContext() {
  return useContext(ExitWarningDispatchContext);
}

export const add = (heading: string): ReducerAction => ({
  type: ReducerActionKind.add,
  heading,
});
export const remove = (heading: string): ReducerAction => ({
  type: ReducerActionKind.remove,
  heading,
});
