import createClient from 'openapi-fetch';
import type { paths } from './gen/pbp-change'; // generated by openapi-typescript
import { PBP_CHANGE_API_BASE_URL } from '../pages/pbp-change/constants';

/**
 * A fully-typed fetch wrapper with which to make calls to SCAN's PBP Change API.
 */
const pbpChangeClient = createClient<paths>({
  baseUrl: PBP_CHANGE_API_BASE_URL,
});

export { pbpChangeClient };
