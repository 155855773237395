import type { EventObject } from 'xstate';
import type {
  EnrollmentPeriod,
  Address,
  ErrorResponse,
  EligiblePlanData,
  CurrentPlanWithPCP,
  SelectedPlanData,
  Provider,
  CommunicationOption,
  PaymentOption,
  SelectedPaymentOption,
  EnrollmentPeriodQuestionAnswer,
  SelectedEnrollmentPeriod,
  SelectedPlan,
  DentalProviderData,
  EsignatureData,
} from '../types';
import { assertDefined } from '../utils';

export interface Context {
  // Required for starting enrollment sessions.
  userId?: string;

  // Required for saving session state.
  enrollmentId?: string;

  communicationOptions?: CommunicationOption[];

  // The API may return more than one period if the user could pick between multiple years.
  enrollmentPeriods?: EnrollmentPeriod[];
  selectedEnrollmentPeriod?: SelectedEnrollmentPeriod;

  splitCountyOptions?: string[];
  addressInformation?: {
    residential: Address;
    mailing?: Address;
  };

  currentPlan?: CurrentPlanWithPCP;
  eligiblePlans?: EligiblePlanData[];
  selectedPlan?: SelectedPlan;

  // We don't know what these errors will look like. This is a placeholder.
  planSelectionIneligibilityErrors?: unknown[];

  selectedPCPId?: string;
  selectedPCP?: Provider;
  dentalInformation?: DentalProviderData;

  selectedCommunicationOption?: CommunicationOption;

  paymentOptions?: PaymentOption[];
  selectedPaymentOption?: SelectedPaymentOption;

  confirmationNumber?: string;

  error?: unknown;
}

export interface Continue extends EventObject {
  type: 'continue';
}

export interface Error extends EventObject {
  type: 'error';
  error: unknown;
}
export interface SelectEnrollmentYear extends EventObject {
  type: 'selectEnrollmentYear';
  year: number;
}
export interface SubmitEligibilityQuestionAnswers extends EventObject {
  type: 'submitEligibilityQuestionAnswers';
  enrollmentYear?: string;
  answers: EnrollmentPeriodQuestionAnswer[];
}

export interface SubmitAddressInfoNotMoving extends EventObject {
  type: 'submitAddressInfoNotMoving';
}

export interface SubmitAddressInfoMoving extends EventObject {
  type: 'submitAddressInfoMoving';
  addresses: Address[];
}

export interface SubmitCounty extends EventObject {
  type: 'submitCounty';
  county: string;
}

export interface SubmitPlanSelection extends EventObject {
  type: 'submitPlanSelection';
  selectedPlan: EligiblePlanData;
  qualifyingQuestionAnswers: SelectedPlanData['qualifyingQuestionAnswers'];
}

export interface ReturnToPlanSelection extends EventObject {
  type: 'returnToPlanSelection';
}

export interface SubmitDentalInformation extends EventObject {
  type: 'submitDentalInformation';
  dentalInformation: DentalProviderData;
}

export interface SubmitCommunicationSelection extends EventObject {
  type: 'submitCommunicationSelection';
  format: CommunicationOption['optionValue'];
}

export interface SubmitPaymentOptionSelection extends EventObject {
  type: 'submitPaymentOptionSelection';
  selectedPaymentOption: SelectedPaymentOption;
}

export interface SubmitEsignature extends EventObject {
  type: 'submitEsignature';
  esignatureData: EsignatureData;
}

export type Event =
  | Continue
  | Error
  | SelectEnrollmentYear
  | SubmitEligibilityQuestionAnswers
  | SubmitAddressInfoNotMoving
  | SubmitAddressInfoMoving
  | SubmitCounty
  | SubmitPlanSelection
  | ReturnToPlanSelection
  | SubmitDentalInformation
  | SubmitCommunicationSelection
  | SubmitPaymentOptionSelection
  | SubmitEsignature;

type NonNullableErrorResponse = {
  [key in keyof ErrorResponse]-?: ErrorResponse[key];
};

export class SaveSessionError extends Error {
  errors: NonNullableErrorResponse[] = [];

  constructor(errors: ErrorResponse[]) {
    super();

    errors.forEach((error) => {
      assertDefined(error.errorCode);
      assertDefined(error.errorDescription);
      this.errors.push({
        errorCode: error.errorCode,
        errorDescription: error.errorDescription,
      });
    });
  }
}
