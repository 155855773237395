import type { DropdownSection } from '@leagueplatform/web-common-components';
import type { Link } from 'common/types/types';
import { ROUTE_PATHS } from 'common/constants/constants';
import { useIntl } from '@leagueplatform/locales';

export type AppDropdownSection = (Omit<DropdownSection[number], 'text'> &
  Link)[];

type UserDropdownItems = {
  heading?: string;
  items: AppDropdownSection;
};

export const useUserDropdownConfig = (): UserDropdownItems[] => {
  const { formatMessage } = useIntl();
  return [
    {
      // Account
      heading: formatMessage({ id: 'STR_ACCOUNT' }),
      items: [
        {
          // Account Information
          text: formatMessage({ id: 'STR_ACCOUNT_INFO' }),
          to: ROUTE_PATHS.ACCOUNT_INFO,
        },
        {
          // Communication Preferences
          text: formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' }),
          to: ROUTE_PATHS.COMMUNICATION_PREFERENCES,
        },
        {
          // Settings
          text: formatMessage({ id: 'STR_SETTINGS' }),
          to: '#',
        },
      ],
    },
    {
      // About
      heading: formatMessage({ id: 'STR_ABOUT' }),
      items: [
        {
          // About SCAN Health
          text: formatMessage({ id: 'STR_ABOUT_SCAN_HEALTH' }),
          to: ROUTE_PATHS.ABOUT,
        },

        {
          // Privacy Policy
          text: formatMessage({ id: 'STR_PRIVACY_POLICY' }),
          to: '#',
        },
        {
          // Terms of Service
          text: formatMessage({ id: 'STR_TERMS_OF_SERVICE' }),
          to: '#',
        },

        {
          // Delete your account
          text: formatMessage({ id: 'STR_DELETE_YOUR_ACCOUNT' }),
          to: '#',
        },
      ],
    },
    {
      items: [
        {
          to: ROUTE_PATHS.SIGN_OUT,
          text: formatMessage({ id: 'STR_LOG_OUT' }),
          linkProps: {
            color: 'onSurface.text.critical',
          },
          color: '$onSurfaceTextCritical',
        },
      ],
    },
  ];
};
