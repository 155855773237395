import React from 'react';
import { useSelector } from '../hooks/useSelector';

/**
 * Shown when the state machine is initializing.
 */
export const DoneView = () => {
  const confirmationNumber = useSelector(
    (snapshot) => snapshot.context.confirmationNumber!,
  );

  return <div>Done! Confirmation number: {confirmationNumber}</div>;
};
