import createClient from 'openapi-fetch';
import type { paths } from './gen/member'; // generated by openapi-typescript
import { MEMBER_API_BASE_URL } from '../pages/pbp-change/constants';

/**
 * A fully-typed fetch wrapper with which to make calls to SCAN's PBP Change API.
 */
const memberClient = createClient<paths>({
  baseUrl: MEMBER_API_BASE_URL,
});

export { memberClient };
