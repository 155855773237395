import {
  Box,
  HeadingText,
  StackLayout,
  Button,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { PAGE_HEADER_MAX_WIDTH } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

interface PageHeaderProps {
  text: string;
  onBackClick?: () => void;
}

export const PageHeader = ({ text, onBackClick }: PageHeaderProps) => {
  const isBelowTablet = useIsBelowTablet();
  const { formatMessage } = useIntl();
  return (
    <Box
      css={{
        backgroundColor: '$surfaceBackgroundTertiary',
        height: isBelowTablet ? 96 : 140,
        paddingInline: '$two',
      }}
    >
      <StackLayout
        orientation="horizontal"
        verticalAlignment="center"
        css={{
          maxWidth: PAGE_HEADER_MAX_WIDTH,
          marginInline: 'auto',
          height: '100%',
        }}
      >
        {onBackClick && (
          <Button
            onClick={onBackClick}
            quiet
            priority="tertiary"
            icon="interfaceArrowLeft"
            size="medium"
            hideLabel
            css={{ marginInlineEnd: '$oneAndHalf' }}
          >
            {formatMessage({ id: 'STR_GO_BACK' })}
          </Button>
        )}
        <HeadingText
          level="1"
          size={isBelowTablet ? 'xl' : 'xxl'}
          // The current typeface has a built-in line height, so it needs to be pushed down to be centred in the parent container. Revisit if the typeface changes.
          css={{ paddingBlockStart: 6 }}
        >
          {text}
        </HeadingText>
      </StackLayout>
    </Box>
  );
};
