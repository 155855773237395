import { forwardRef } from 'react';
import { useFormContext } from '@leagueplatform/web-common';
import { GDSTextInputProps, TextInput } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { RHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';
import type { MergeWithRHFInputWrapper } from 'components/form-elements/rhf-input-wrapper.component';

export const RHFTextInput = forwardRef<
  HTMLInputElement,
  MergeWithRHFInputWrapper<GDSTextInputProps>
>(
  (
    { type, id, label, hint, required, registerOptions, ...restComponentProps },
    ref,
  ) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    // Compose ariadescribedby for hint and error message
    const ariaDescribedBy: string[] = [];
    if (errors?.[id]?.message) ariaDescribedBy.push(`${id}-error`);
    if (hint) ariaDescribedBy.push(`${id}-hint`);

    const { formatMessage } = useIntl();

    const requiredErrorMessage = formatMessage(
      { id: 'STR_REQUIRED' },
      { fieldName: label },
    );

    const { ref: RHFRegisterRef, ...restRHFRegisterProps } = register(id, {
      ...registerOptions,
      required: required && requiredErrorMessage,
    });

    return (
      <RHFInputWrapper id={id} label={label} hint={hint} required={required}>
        <TextInput
          type={type}
          id={id}
          {...(errors?.[id]?.message && { inputStatus: 'error' })}
          {...(ariaDescribedBy.length > 0 && {
            'aria-describedby': ariaDescribedBy.join(' '),
          })}
          {...restComponentProps}
          {...restRHFRegisterProps}
          ref={ref || RHFRegisterRef}
        />
      </RHFInputWrapper>
    );
  },
);
