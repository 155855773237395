import { leagueFetch } from '@leagueplatform/league-fetch';
import { useQuery } from 'react-query';
import { API_PATHS, QUERY_KEYS } from 'common/constants/api';
import { components } from 'api/gen/communication-preferences';
import { assertDefined } from 'pages/pbp-change/utils';
import type { CommunicationPreferencesSubmitVariables } from 'hooks/use-patch-communication-preferences/use-patch-communication-preferences.hook';

// Uses the section and preference name to create a unique identifier for the form's fields.
// Subject to change when staging API's are available.
export const normalizeFieldName = (
  sectionName: string,
  preferenceName: string,
): string => {
  const formattedName = (name: string) =>
    name.toLowerCase().replaceAll(' ', '-');
  return `${formattedName(sectionName)}_${formattedName(preferenceName)}`;
};

export const getFormDefaultValues = (
  communicationPreferencesData: components['schemas']['getCommunicationPreferencesSchema']['data'],
): CommunicationPreferencesSubmitVariables => {
  let preferredMethods = {};
  assertDefined(communicationPreferencesData);

  communicationPreferencesData.forEach((section) => {
    assertDefined(section.attributes.preferences);

    section.attributes.preferences.forEach((preference) => {
      assertDefined(section.attributes.sectionName);
      assertDefined(preference.attributes.name);
      assertDefined(preference.attributes.preferredMethod);

      const formattedName = normalizeFieldName(
        section.attributes.sectionName,
        preference.attributes.name,
      );
      const method =
        preference.attributes.preferredMethod.communicationMethodCode;
      preferredMethods = {
        ...preferredMethods,
        [formattedName]: method,
      };
    });
  });

  return preferredMethods;
};

export const useGetCommunicationPreferences = () =>
  useQuery({
    queryKey: [QUERY_KEYS.COMMUNICATION_PREFERENCES],
    queryFn: async () => {
      const response = await leagueFetch(API_PATHS.COMMUNICATION_PREFERENCES);

      if (!response?.ok) {
        throw new Error(
          `Error Response Code ${response?.status}: ${await response.text()}`,
        );
      }

      return (await response.json()) as Promise<
        components['schemas']['getCommunicationPreferencesSchema']
      >;
    },
    select: (data) => data?.data,
    suspense: true,
  });
