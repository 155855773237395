import {
  Button,
  Checkbox,
  Modal,
  queryHelpers,
  Radio,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import {
  ControlledFieldset,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFormContext,
  useInput,
  useWatch,
} from '@leagueplatform/web-common';
import { APIProvider } from '@vis.gl/react-google-maps';
import { AddressForm } from 'components/forms/address-form/address-form.component';
import React, { useCallback } from 'react';
import { Address } from '../types';
import { useSend } from '../hooks/useSend';
import { useSelector } from '../hooks/useSelector';

type AddressFields = Omit<Address, 'addressType'>;

/**
 * The shape of the RHF field values populated by the `<AddressForm>` component.
 */
type TypedAddressFieldValues<Type extends Address['addressType']> = {
  [key in `${Type}_${keyof AddressFields}`]?: string;
};

/**
 * The shape of the RHF field values of the entire form.
 */
type FormFieldValues = {
  isMoving: 'yes' | 'no';
} & TypedAddressFieldValues<'residential'> & {
    hasDifferentMailingAddress: boolean;
  } & TypedAddressFieldValues<'mailing'>;

/**
 * Renders All fields in the form, not including the conditionally rendered
 * "split county select" view.
 */
export const Fields = () => {
  const isMoving = useWatch<FormFieldValues, 'isMoving'>({
    name: 'isMoving',
  });

  const hasDifferentMailingAddress = useWatch<
    FormFieldValues,
    'hasDifferentMailingAddress'
  >({
    name: 'hasDifferentMailingAddress',
  });

  const { field: hasDifferentMailingAddressFieldProps } = useInput(
    'hasDifferentMailingAddress',
  );

  return (
    <>
      <ControlledFieldset
        name="isMoving"
        inputOptions={{ required: true }}
        legend="Are you moving?"
      >
        <Radio
          name="isMoving"
          id="isMoving-no"
          label="No, I am not moving"
          value="no"
        />
        <Radio
          name="isMoving"
          id="isMoving-yes"
          label="Yes, I am moving"
          value="yes"
        />
      </ControlledFieldset>
      {isMoving === 'yes' && (
        <>
          <AddressForm addressType="residential" includeZipCode />
          <Checkbox
            id="hasDifferentMailingAddress"
            {...hasDifferentMailingAddressFieldProps}
            label="I have a different mailing address"
          />
          {hasDifferentMailingAddress && (
            <AddressForm addressType="mailing" includeZipCode />
          )}
        </>
      )}
    </>
  );
};

type CountySelectionProps = {
  splitCountyOptions: string[];
  /**
   * We will call this submit handler when the button in this module is clicked,
   * so as to submit the entire form *including* the radio field that appear inside this
   * module.
   */
  parentFormSubmitHandler: SubmitHandler<FormFieldValues>;
};

/**
 * Render an immediately opened modal with a radio select between counties.
 */
const CountySelection = ({
  splitCountyOptions,
  parentFormSubmitHandler,
}: CountySelectionProps) => {
  const formContext = useFormContext<FormFieldValues>();

  const submitClickHandler = useCallback(() => {
    formContext.handleSubmit(parentFormSubmitHandler)();
  }, [formContext, parentFormSubmitHandler]);

  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <Modal.Root modal={!!isMobile} open>
      <Modal.Content
        showCloseButton={false}
        layout="standard"
        onInteractOutside={(event) => event.preventDefault()}
      >
        <ControlledFieldset
          name="residential_county"
          inputOptions={{ required: true }}
          legend="Which county?"
        >
          {splitCountyOptions.map((county) => (
            <Radio
              name="residential_county"
              id={`residential_county-${county}`}
              label={county}
              value={county}
            />
          ))}
        </ControlledFieldset>
        <Button type="button" onClick={submitClickHandler}>
          Try again
        </Button>
      </Modal.Content>
    </Modal.Root>
  );
};

/**
 * Shown when the user is ready to optionally speficy a new residential and/or
 * mailing address.
 */
export const AddressInfoView = () => {
  const send = useSend();

  const submitHandler = useCallback(
    (fieldValues: FieldValues) => {
      const { isMoving, hasDifferentMailingAddress, ...rest } =
        fieldValues as FormFieldValues;

      if (isMoving === 'no') {
        send({
          type: 'submitAddressInfoNotMoving',
        });
      } else {
        /**
         * Transform the field values object to two distinct {@link Address `Address`}
         * objects, as expected by the `/save-session` API.
         */
        const [residential, mailing] = Object.entries(rest).reduce(
          (acc, [name, value]) => {
            const [addressType, fieldName] = name.split('_');

            acc[addressType === 'residential' ? 0 : 1][
              fieldName as keyof Omit<Address, 'addressType'>
            ] = value;

            return acc;
          },
          [
            { addressType: 'residential' } as Address,
            { addressType: 'mailing' } as Address,
          ],
        );

        const addresses = [residential];

        if (hasDifferentMailingAddress) {
          addresses.push(mailing);
        }

        send({
          type: 'submitAddressInfoMoving',
          addresses,
        });
      }
    },
    [send],
  );

  const splitCountyOptions = useSelector(
    (snapshot) => snapshot.context.splitCountyOptions,
  );

  return (
    <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
      <FormProvider formOptions={{ mode: 'onSubmit' }} onSubmit={submitHandler}>
        <Fields />
        {/* If the `splitCountyOptions` property in the machine context is an array
        with elements, it means that we have come back to the `awaitingSubmission`
        sub-state of the `addressInfo` state due to a "split county" error returned
        by the API. We will now show the "select county" module, whose fields
        will become part of this same form and submitted along with the rest
        when the user clicks "try again". */}
        {splitCountyOptions?.length && (
          <CountySelection
            splitCountyOptions={splitCountyOptions}
            parentFormSubmitHandler={submitHandler}
          />
        )}
        <Button type="submit">Submit</Button>
      </FormProvider>
    </APIProvider>
  );
};
