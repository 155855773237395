import {
  CommunicationOption,
  EligiblePlanData,
  EnrollmentPeriod,
  EnrollmentPeriodQuestionAnswer,
  PaymentOption,
  SelectedEnrollmentPeriod,
  SelectedPaymentOption,
  SelectedPlan,
  SelectedPlanData,
} from './types';

/**
 * throws an error if the passed value is undefined. Also, if the type of the passed
 * value is non-nullable, asserts that its type is `never` - thus this guard
 * can be used on values that are currently typed as nullable but that we believe
 * will change to become non-nullable in the future (e.g., when the API spec improves),
 * at which point typescript errors will be thrown after the assertion, helping us
 * catch unnecessary nullability checks.d
 */
export function assertDefined<T>(
  value: T,
): asserts value is undefined extends T ? NonNullable<T> : never {
  if (typeof value === 'undefined') {
    throw new Error('Value is undefined!');
  }
}

export const formatEnrollmentAnswersForContext = (
  enrollmentPeriodQuestions: NonNullable<EnrollmentPeriod['questions']>,
  answers: EnrollmentPeriodQuestionAnswer[],
): NonNullable<SelectedEnrollmentPeriod['answers']> =>
  answers.map((answer) => {
    const question = enrollmentPeriodQuestions.find(
      ({ id }) => id === answer.questionId,
    );

    assertDefined(question);

    return {
      ...question,
      questionDate: answer.questionDate,
    };
  });

export const formatQualifyingAnswersForContext = (
  qualifyingQuestions: NonNullable<EligiblePlanData['questions']>,
  answers: NonNullable<SelectedPlanData['qualifyingQuestionAnswers']>,
): NonNullable<SelectedPlan['answers']> =>
  answers.map((answer) => {
    assertDefined(answer.selectedValue);

    const { options, ...questionProperties } = qualifyingQuestions.find(
      ({ questionId }) => answer.questionId === questionId,
    )!;

    const selectedValues = answer.selectedValue.split(',');

    return {
      ...questionProperties,
      options: options.filter((option) =>
        selectedValues.includes(option.value),
      ),
    };
  });

export const formatCommunicationOptionForContext = (
  communicationOptions: CommunicationOption[],
  selectedCommunicationOption: CommunicationOption['optionValue'],
): CommunicationOption =>
  communicationOptions.find(
    (option) => option.optionValue === selectedCommunicationOption,
  )!;

export const formatPaymentOptionForContext = (
  paymentOptions: PaymentOption[],
  selectedPaymentOption: PaymentOption['optionValue'],
  selectedPaymentOptionQuestion?: NonNullable<
    PaymentOption['optionQuestions']
  >[number]['optionQuestionValue'],
): SelectedPaymentOption => {
  const { optionValue, optionLabel, optionQuestions } = paymentOptions.find(
    (option) => option.optionValue === selectedPaymentOption,
  )!;
  const optionQuestion = optionQuestions?.find(
    (question) =>
      question.optionQuestionValue === selectedPaymentOptionQuestion,
  );

  return {
    optionValue,
    optionLabel,
    optionQuestion,
  };
};
