import { useDocumentTitle } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';
import { PageHeader } from 'components/page-header/page-header.component';
import {
  Box,
  ParagraphText,
  StackLayout,
  styled,
} from '@leagueplatform/genesis-core';
import { PAGE_HEADER_MAX_WIDTH } from 'common/constants/ui';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';
import scanLogo from 'assets/images/scan-logo.png';

const CONTENT_MAX_WIDTH = 952;

const Logo = styled('img', {
  width: '134px',
});

export const AboutPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_ABOUT_SCAN_HEALTH' }));

  const isBelowTablet = useIsBelowTablet();

  return (
    <>
      <PageHeader text={formatMessage({ id: 'STR_ABOUT_SCAN_HEALTH' })} />
      <Box css={{ paddingInline: '$two' }}>
        <Box
          css={{
            maxWidth: PAGE_HEADER_MAX_WIDTH,
            marginInline: 'auto',
            paddingBlockStart: '$two',
            paddingBlockEnd: '$five',
          }}
        >
          <StackLayout
            orientation={isBelowTablet ? 'vertical' : 'horizontal'}
            verticalAlignment="center"
            horizontalAlignment="center"
            css={{
              maxWidth: CONTENT_MAX_WIDTH,
              marginBlock: 'auto',
              paddingBlock: '$twoAndHalf',
              gap: '$three',
            }}
          >
            <Box
              css={{
                flexShrink: 0,
                backgroundColor: '$decorativeBrandSecondaryDefault',
                borderRadius: '50%',
                height: 160,
                width: 160,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Logo
                role="presentation"
                src={scanLogo}
                alt={formatMessage({ id: 'STR_COMPANY_NAME' })}
              />
            </Box>
            <ParagraphText>
              {formatMessage({ id: 'STR_ABOUT_SCAN_HEALTH_CONTENT' })}
            </ParagraphText>
          </StackLayout>
        </Box>
      </Box>
    </>
  );
};
