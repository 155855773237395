import * as React from 'react';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { AppNavBarComponent } from 'components/header-nav/nav.component';

import { GlobalFooter } from './global-footer/global-footer.component';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => (
  <Box
    css={{
      height: '100vh',
      display: 'grid',
      gridTemplateColumns: '100%',
      gridTemplateRows: 'auto 1fr auto',
      overflowX: 'hidden',
    }}
  >
    {isPrivatePage && <AppNavBarComponent />}
    <Box
      as="main"
      css={{
        position: 'relative',
        backgroundColor: '$surfaceBackgroundPrimary',
        ...css,
      }}
    >
      {children}
    </Box>
    {isPrivatePage && <GlobalFooter />}
  </Box>
);
