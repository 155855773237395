import { Button, Radio } from '@leagueplatform/genesis-core';
import { ControlledFieldset, FormProvider } from '@leagueplatform/web-common';
import React, { useCallback } from 'react';
import { useSend } from '../hooks/useSend';
import { useSelector } from '../hooks/useSelector';

type FormFieldValues = {
  format?: string;
};

/**
 * Shown when the user is ready to provide their communications preferences.
 */
export const CommunicationsView = () => {
  const send = useSend();
  const submitHandler = useCallback(
    (fieldValues: FormFieldValues) => {
      if (fieldValues.format) {
        send({
          type: 'submitCommunicationSelection',
          format: fieldValues.format,
        });
      } else {
        send({ type: 'continue' });
      }
    },
    [send],
  );

  const communicationOptions = useSelector(
    (snapshot) => snapshot.context.communicationOptions!,
  );

  return (
    <FormProvider<FormFieldValues>
      formOptions={{ mode: 'onSubmit' }}
      onSubmit={submitHandler}
    >
      <ControlledFieldset
        name="format"
        inputOptions={{}}
        legend="Do you want to receive our materials through an accessible format?"
      >
        {communicationOptions.map(({ optionLabel, optionValue }) => (
          <Radio
            key={optionValue}
            name="format"
            id={`format-${optionValue}`}
            label={optionLabel}
            value={optionValue}
          />
        ))}
      </ControlledFieldset>
      <Button type="submit">Submit</Button>
    </FormProvider>
  );
};
