import React, { type ReactNode } from 'react';
import {
  StackLayout,
  type GDSHorizontalStackLayoutProps,
  GDSStyleObject,
} from '@leagueplatform/genesis-core';
import { useIsBelowTablet } from 'hooks/use-is-below-tablet/use-is-below-tablet.hooks';

type ResponsiveButtonRowProps = {
  primaryBtn: ReactNode;
  secondaryBtn: ReactNode;
  layoutProps?: Omit<GDSHorizontalStackLayoutProps, 'children'>;
  css?: GDSStyleObject;
};

export const ResponsiveButtonRow = ({
  primaryBtn,
  secondaryBtn,
  layoutProps = {},
  css = {},
}: ResponsiveButtonRowProps) => {
  const isBelowTablet = useIsBelowTablet();
  const { horizontalAlignment, ...restLayoutProps } = layoutProps;

  return (
    <StackLayout
      orientation={isBelowTablet ? 'vertical' : 'horizontal'}
      spacing="$one"
      css={{
        width: '100%',
        ...css,
      }}
      horizontalAlignment={horizontalAlignment || 'end'}
      {...restLayoutProps}
    >
      {isBelowTablet ? (
        <>
          {primaryBtn}
          {secondaryBtn}
        </>
      ) : (
        <>
          {secondaryBtn}
          {primaryBtn}
        </>
      )}
    </StackLayout>
  );
};
