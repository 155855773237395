import React, { type FunctionComponent } from 'react';
import { SnapshotFrom } from 'xstate';
import { useSelector } from '../hooks/useSelector';
import { InitializingView } from '../views/initializing';
import { EligibilityQuestionsView } from '../views/eligibility-questions';
import { AddressInfoView } from '../views/address-info';
import { SelectPlanView } from '../views/select-plan';
import { YourCareTeamView } from '../views/your-care-team';
import { CommunicationsView } from '../views/communications';
import { PaymentInformationView } from '../views/payment-information';
import { ReviewView } from '../views/review';
import { EsignView } from '../views/esign';
import { ErrorView } from '../views/error';
import { DoneView } from '../views/done';
import { PBPChangeMachine } from '../state-machine/pbp-change-machine';

type ExtractStringMembersAndKeys<T> = T extends string
  ? T
  : T extends object
  ? keyof T
  : never;

type TopLevelStateName = ExtractStringMembersAndKeys<
  SnapshotFrom<PBPChangeMachine>['value']
>;

const STATE_VIEW_MAP: Record<TopLevelStateName, FunctionComponent> = {
  initializing: InitializingView,
  eligibilityQuestions: EligibilityQuestionsView,
  addressInfo: AddressInfoView,
  selectPlan: SelectPlanView,
  yourCareTeam: YourCareTeamView,
  communications: CommunicationsView,
  paymentInformation: PaymentInformationView,
  review: ReviewView,
  esign: EsignView,
  done: DoneView,
  error: ErrorView,
};

/**
 * Listens to the current state the machine is in and displays a "view" corresponding
 * to it.
 */
export const View = () => {
  const topLevelState = useSelector(
    (snapshot) =>
      (typeof snapshot.value === 'string'
        ? snapshot.value
        : Object.keys(snapshot.value)[0]) as TopLevelStateName,
  );

  const ViewForState = STATE_VIEW_MAP[topLevelState];

  return <ViewForState />;
};
